<template>
  <div class="pb-4" style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; font-size: 22px; margin-bottom: 0" class="custom-title-color-black">
              Statistik Pembelian
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row class="m-0 p-0">
        <b-col cols="6">
          <label for="tanggal">Bulan - Tahun</label>
          <date-picker
            style="width: 100%"
            id="tanggal"
            format="MMMM - YYYY"
            type="month"
            v-model="dataFilter.tanggal"
          ></date-picker>
        </b-col>
        <b-col cols="6">
          <label for="puskesmas_id">Puskesmas</label>
          <Multiselect
            id="puskesmas_id"
            v-model="dataFilter.puskesmas_id"
            :options="listPuskesmas"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama_puskesmas"
            track-by="id"
            placeholder="-- Pilih Puskesmas --"
            size="sm"
          ></Multiselect>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button 
              class="mr-2"
              :disabled="busy"
              @click="reset()"
              variant="danger"
            >Reset</b-button>
            <b-button
              :disabled="busy"
              @click="cari()"
              variant="success"
            >Cari</b-button>
          </div>
        </b-col>
        <b-col cols="12">
          <canvas id="myChart" ref="pendapatan_harian" style="width: 100%"></canvas>
        </b-col>
        <!-- <b-col cols="12">
          <b-table
            class="px-3"
            :items="listTransaksi"
            :fields="fieldsPendapatanHarian"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="busy"
          >
            <template #cell(tanggal)="item">{{$moment(item.item.tanggal).format('LL')}}</template>
            <template #cell(jumlah)="item">{{$numberFormat(item.item.jumlah, 'idr')}}</template>
          </b-table>
        </b-col> -->
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {Chart, registerables } from 'chart.js'
Chart.register(...registerables );
export default {
  components:{
  },
  data() {
    return {
      busy: false,
      myChart: null,
      dataFilter: {
        tanggal: null,
        puskesmas_id: null,
      },
      listPuskesmas: [],
      listTransaksi: [],
      fieldsPendapatanHarian: [
        {
          key: "tanggal",
          label: "Tanggal",
          sortDirection: "desc",
          sortable: true,
          class: "text-left",
        },
        {
          key: "jumlah",
          label: "Jumlah",
          sortable: true,
          sortDirection: "desc",
          class: "text-right",
        },
      ],
      
    }
  },
  computed: {
    
  },
  activated() {
    const vm = this
    vm.getData()
    vm.myChart = new Chart(this.$refs.pendapatan_harian, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'APBD',
            data: [],
            backgroundColor: [
              '#38c172',
            ],
            borderColor: [
              '#38c172',
            ],
            hoverOffset: 4
          },
          {
            label: 'Non APBD',
            data: [],
            backgroundColor: [
              '#3490dc',
            ],
            borderColor: [
              '#3490dc',
            ],
            hoverOffset: 4
          },
          {
            label: 'Alkes',
            data: [],
            backgroundColor: [
              '#e3342f',
            ],
            borderColor: [
              '#e3342f',
            ],
            hoverOffset: 4
          },
          {
            label: 'Obat',
            data: [],
            backgroundColor: [
              '#f6993f',
            ],
            borderColor: [
              '#f6993f',
            ],
            hoverOffset: 4
          },
        ]
      },
      options: {
        plugins: {
          legend: {
            // display: false
          },
        },
      }
    })
    vm.myChart.update()
    vm.reset()
  },
  methods: {
    pilihSemuaPoli(){
      const vm = this
      vm.dataFilter.bulk_puskesmas_id = []
      console.log('vm.dataFilter.allPoli', vm.dataFilter.allPoli)
      if(vm.dataFilter.allPoli){
        // vm.dataFilter.allPoli = true
        for (let i = 0; i < vm.listPuskesmas.length; i++) {
          vm.dataFilter.bulk_puskesmas_id.push(vm.listPuskesmas[i].id)
        }
      }
    },
    async cari(){
      const vm = this
      vm.busy = false
      try {
        // console.log('vm.dataFilter.tanggal', vm.dataFilter.tanggal)
        const res = await vm.$axios.post('/pembelian/laporan_grafik_statistik_pembelian', {
          tahun: vm.$moment(vm.dataFilter.tanggal).format('YYYY'),
          bulan: vm.$moment(vm.dataFilter.tanggal).format('MM'),
          puskesmas_id: vm.dataFilter.puskesmas_id ? vm.dataFilter.puskesmas_id.id : null,
        })
        // console.log('grafik_pendapatan_harian', res)
        if(res.data.status == 200){
          vm.listTransaksi = res.data.data
          vm.myChart.data.labels = []
          vm.myChart.data.datasets[0].data = []
          vm.myChart.data.datasets[1].data = []
          vm.myChart.data.datasets[2].data = []
          vm.myChart.data.datasets[3].data = []
          for (let i = 0; i < vm.listTransaksi.length; i++) {
            const x = vm.listTransaksi[i];
            vm.myChart.data.labels.push(vm.$moment(x.tanggal_pembelian).format('DD'))
            vm.myChart.data.datasets[0].data.push(x.data.apbd)
            vm.myChart.data.datasets[1].data.push(x.data.non_apbd)
            vm.myChart.data.datasets[2].data.push(x.data.alkes)
            vm.myChart.data.datasets[3].data.push(x.data.obat)
          }
          vm.myChart.update()
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async getData(){
      const vm = this
      vm.busy = false
      try {
        const listPuskesmas = await vm.$axios.post('/puskesmas/list')
        vm.listPuskesmas = listPuskesmas.data.status == 200 ? listPuskesmas.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      // console.log('vm.$store.state.tanggal_sesi', vm.$store.state.tanggal_sesi)
      vm.dataFilter = {
        tanggal: new Date(vm.$store.state.tanggal_sesi),
        puskesmas_id: null,
      }
      vm.cari()
    }
  },
}
</script>